import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createOrder } from '~/redux/actions/orders';
import EmailForm from '~/components/Checkout/CustomerEmail';
import ShippingForm from '~/components/Checkout/CustomerShipping';
// import BillingForm from '~/components/Checkout/CustomerBilling';
import PaymentForm from '~/components/Checkout/CustomerPayment';
import { Accordion, useAccordionToggle, Card, AccordionContext, Spinner } from 'react-bootstrap';
import styles from './index.module.scss';
function EditField({ children, field, eventKey, setSteps, visited = true }) {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionToggle(eventKey, () => setSteps(eventKey));
	const isCurrentEventKey = currentEventKey === eventKey;
	return (
		<div className={styles.collapsable}>
			<div
				className={`${styles.cardHeader} ${
					isCurrentEventKey ? styles.activeCard : styles.inactiveCard
				}`}
			>
				<div className={styles.cardTitle}>
					<h3>
						<span>
							<em>{+eventKey + 1}</em>
						</span>
						{field}
					</h3>
					{!isCurrentEventKey && <div className={styles.children}>{children}</div>}
				</div>

				{visited && (
					<div className={styles.editButton}>
						<button type="button" onClick={decoratedOnClick}>
							Edit
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

const ShowAddress = ({ address, isLoading }) =>
	isLoading ? (
		<Spinner animation="border" role="status" variant="secondary" />
	) : (
		<ul>
			{address.first_name || address.last_name ? (
				<li>{`${address.first_name} ${address.last_name}`.trim()}</li>
			) : null}
			{address.company || address.phone ? (
				<li>{`${address.company} ${address.phone}`.trim()}</li>
			) : null}
			{address.address1 ? <li>{`${address.address1} ${address.address2}`.trim()}</li> : null}
			{address.city || address.state_or_province || address.country_code ? (
				<li>
					{`${address.city} ${address.state_or_province} ${address.postal_code} / ${address.country_code}`.trim()}
				</li>
			) : null}
		</ul>
	);

const Checkout = ({ email, shipping, billing, isLoading, createOrder, orderErrorMsg }) => {
	const [steps, setSteps] = useState('0');
	const [visited, setVisited] = useState([]);
	useEffect(() => {
		if (visited.indexOf(steps) < 0) {
			if (steps === '2') createOrder();
			setVisited(state => [...state, steps]);
		}
	}, [steps, setVisited, visited, createOrder]);
	const setNextSteps = num => {
		setSteps(num);
	};

	return (
		<>
			<div className={styles.checkout}>
				<Accordion activeKey={steps}>
					<Card>
						<Card.Header>
							<EditField
								eventKey="0"
								field="Customer"
								setSteps={setSteps}
								visited={visited.indexOf('0') > -1}
							>
								<span>{email}</span>
							</EditField>
						</Card.Header>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
								<EmailForm setSteps={() => setNextSteps('1')} />
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					<Card>
						<Card.Header>
							<EditField
								eventKey="1"
								field="Shipping"
								setSteps={setSteps}
								visited={visited.indexOf('1') > -1}
							>
								<ShowAddress address={shipping} isLoading={isLoading} />
							</EditField>
						</Card.Header>
						<Accordion.Collapse eventKey="1">
							<Card.Body>
								<ShippingForm
									setSteps={() => setNextSteps('2')}
									// Commented out for future use of billing address form
									// skipBilling={() => setNextSteps('3')}
								/>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					{/* 
					// Commented out for future use of billing address form
					<Card>
						<Card.Header>
							<EditField
								eventKey="2"
								field="Billing"
								setSteps={setSteps}
								visited={visited.indexOf('2') > -1 || visited.indexOf('1') > -1}
							>
								<ShowAddress address={billing} isLoading={isLoading} />
							</EditField>
						</Card.Header>
						<Accordion.Collapse eventKey="2">
							<Card.Body>
								<BillingForm setSteps={() => setNextSteps('3')} />
							</Card.Body>
						</Accordion.Collapse>
					</Card> */}
					<Card>
						<Card.Header>
							<EditField
								eventKey="2"
								field="Payment"
								setSteps={setSteps}
								visited={visited.indexOf('2') > -1}
							/>
						</Card.Header>
						<Accordion.Collapse eventKey="2">
							<Card.Body>
								{orderErrorMsg.length > 0 ? <span>{orderErrorMsg}</span> : <PaymentForm />}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			</div>
		</>
	);
};

Checkout.propTypes = {
	isLoading: PropTypes.bool,
	orderErrorMsg: PropTypes.string,
	email: PropTypes.string,
	shipping: PropTypes.object,
	billing: PropTypes.object,
};

const mapStateToProps = state => ({
	isLoading: state.shipping.isLoading,
	email: state.customer.email,
	shipping: state.customer.shipping,
	billing: state.customer.billing,
	orderErrorMsg: state.orders.orderErrorMsg,
});

export default connect(mapStateToProps, { createOrder })(Checkout);
