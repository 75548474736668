import * as ActionType from '~/redux/constants/actionTypes';
import { api } from '~/apis';
import { logError } from '~/utils/_logError';
import { isSubscriptionOnly } from '~/utils';

export const createOrder = () => async (dispatch, getState) => {
	dispatch({
		type: ActionType.ORDERS_LOAD,
	});

	// This action is only intended to be used with shipping info.
	// This is a hack to get around that to handle cases where there
	// is billing info but not shipping info.
	const infoType = getState().customer.shipping.first_name ? 'shipping' : 'billing';

	const email = getState().customer.email;
	const first_name = getState().customer[infoType].first_name;
	const last_name = getState().customer[infoType].last_name;
	const phone = getState().customer[infoType].phone;
	const cart_id = getState().cart.data.cart.id;
	try {
		const orderRes = await api.post('/orders/store', { cart_id });
		const paymentRequestBody = {
			order_id: orderRes.data.orderId,
			name: `${first_name} ${last_name}`,
			phone,
			email,
		};
		const paymentRes = await api.post('/payments/store/intent', paymentRequestBody);
		dispatch({
			type: ActionType.ORDERS_CREATED,
			payload: { order_id: orderRes.data.orderId, ...paymentRes.data },
		});
	} catch (err) {
		logError({ message: 'Failed to create order' }, `Failed to create order. cart id: ${cart_id}`);
		dispatch({
			type: ActionType.ORDERS_ERROR,
			payload: {
				orderErrorMsg: 'There was a problem completing your order.	Please try again later.',
			},
		});
	}
};

export const completeOrder = history => async (dispatch, getState) => {
	dispatch({
		type: ActionType.ORDERS_LOAD_STRIPE,
	});
	const order_id = getState().orders.order_id;
	const customer_id = getState().orders.customer;
	const iso = isSubscriptionOnly(getState());
	try {
		const reqBody = {
			status: iso ? 10 : 11,
			customer_id,
		};
		const url = iso ? `/orders/store/subscription/${order_id}` : `/orders/store/${order_id}`;
		await api.put(url, reqBody);
		dispatch({
			type: ActionType.ORDERS_COMPLETE,
			payload: { order_status: true },
		});
		history.push('/confirmation');
	} catch (err) {
		logError(
			{ message: 'Failed to complete order' },
			`Failed to complete order. order id: ${order_id}`
		);
		dispatch({
			type: ActionType.ORDERS_ERROR,
			payload: {
				orderErrorMsg: 'There was a problem completing your order.	Please try again later.',
			},
		});
	}
};
