import cart from './cart';
import customer from './customer';
import shipping from './shipping';
import orders from './orders';
import { combineReducers } from 'redux';

export default combineReducers({
	customer,
	cart,
	shipping,
	orders,
});
