/* eslint-disable no-unused-vars */
import * as ActionType from '~/redux/constants/actionTypes';

const initialState = {
	isLoading: true,
	data: {
		cart: {
			id: null,
			currency: null,
			tax_included: null,
			cart_amount_ex_tax: null,
			shipping_cost: null,
			grand_total: null,
			discount_amount: null,
			coupons: [],
			line_items: {
				physical_items: [],
				digital_items: [],
				gift_certificates: [],
				custom_items: [],
			},
		},
		shipping_cost_total_ex_tax: null,
		tax_total: null,
		subtotal_ex_tax: null,
	},
};

const cartReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ActionType.CREATE_CART:
			return {
				...state,
				isLoading: true,
			};
		case ActionType.LOAD_CART:
		case ActionType.CART_UPDATE:
			return {
				...state,
				data: { ...state.data, ...payload },
				isLoading: false,
			};
		default:
			return state;
	}
};

export default cartReducer;
