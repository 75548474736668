import React from 'react';
import styles from './index.module.scss';
import { Form, FormCheck, Spinner } from 'react-bootstrap';

const ShippingMethodSelect = ({ isLoading, available_shipping_options, setShippingOption }) => {
	return available_shipping_options.length ? (
		<div className={styles.shippingMethodContainer}>
			{available_shipping_options.map(option => (
				<Form.Check key={option.description} className={styles.inputRow}>
					<FormCheck className={styles.check}>
						<FormCheck.Input
							className={styles.input}
							name="shipping_option_id"
							type="radio"
							value={option.id}
							id={option.id}
							onClick={e => {
								setShippingOption(e.target.value);
							}}
						/>
						<FormCheck.Label className={styles.label} htmlFor={option.id}>
							<div>
								<span>{option.description}</span>
								<span>${option.cost}</span>
							</div>
						</FormCheck.Label>
					</FormCheck>
				</Form.Check>
			))}
		</div>
	) : (
		<div className={styles.shippingMethodContainer}>
			<div className={styles.invalidShipping}>
				{isLoading ? (
					<Spinner animation="border" variant="secondary" />
				) : (
					<span>Please enter a shipping address in order to see shipping quotes</span>
				)}
			</div>
		</div>
	);
};

export default ShippingMethodSelect;
