import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * helpful sources:
 * https://www.freeformatter.com/usa-state-list-html-select.html
 * https://en.wikipedia.org/wiki/ISO_3166-2:US
 */

export const Base50 = {
	AL: 'Alabama',
	AK: 'Alaska',
	AS: 'American Samoa',
	AZ: 'Arizona',
	AR: 'Arkansas',
	AA: 'Armed Forces Americas',
	AP: 'Armed Forces Pacific',
	AE: 'Armed Forces Others',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District Of Columbia',
	FL: 'Florida',
	GA: 'Georgia',
	GU: 'Guam',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	MP: 'Northern Mariana Islands',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	PR: 'Puerto Rico',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UM: 'United States Minor Outlying Islands',
	VI: 'U.S. Virgin Islands',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};

function Option({ code, name, valueType, visibleType }) {
	const finalValue = valueType === 'code' ? code : name;
	const finalText = visibleType === 'code' ? code : name;
	return <option value={finalValue}>{finalText}</option>;
}

function USStateSelect({ valueType, visibleType, ...passThrouhProps }) {
	return (
		<Form.Control as="select" {...passThrouhProps} className="checkout-form">
			<option value="">--</option>
			{Object.keys(Base50).map(key => {
				return (
					<Option
						key={key}
						code={key}
						name={Base50[key]}
						valueType={valueType}
						visibleType={visibleType}
					/>
				);
			})}
		</Form.Control>
	);
}

USStateSelect.defaultProps = {
	valueType: 'code',
	visibleType: 'code',
};

export default USStateSelect;
