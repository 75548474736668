/* eslint-disable no-unused-vars */
import * as ActionType from '~/redux/constants/actionTypes';

const initialState = {
	isLoading: false,
	stripeLoading: false,
	customer: '',
	intent: '',
	order_id: '',
	order_status: false,
	orderErrorMsg: '',
};

const ordersReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ActionType.ORDERS_LOAD:
			return {
				...state,
				isLoading: true,
			};
		case ActionType.ORDERS_LOAD_STRIPE:
			return {
				...state,
				stripeLoading: true,
			};
		case ActionType.ORDERS_CREATED:
			return {
				...state,
				...payload,
				isLoading: false,
			};
		case ActionType.ORDERS_COMPLETE:
			return {
				...state,
				...payload,
				stripeLoading: false,
			};
		case ActionType.ORDERS_ERROR:
			return {
				...state,
				...payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default ordersReducer;
