import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Header from '~/components/Header';
import OrderSummary from '~/components/OrderSummary';
import { redirectUrl } from '~/utils';
import styles from './index.module.scss';

const Confirmation = ({ order_id, first_name, order_status, history }) => {
	useEffect(() => {
		if (!order_status) {
			history.push('/404');
		}
	}, [order_status, history]);
	const continueShoppingLink = redirectUrl();
	return (
		<div className={styles.storePage}>
			<Header />
			<div className={styles.main}>
				<div className={styles.container}>
					<React.Fragment>
						<div className={styles.confirmationMsg}>
							<h1>Thank you {first_name}!</h1>
							<p>
								Your order number is <b>{order_id}</b>
							</p>
							<p>
								An email will be sent containing information about your purchase. If you have any questions
								about your purchase, email us at{' '}
								<a target="_blank" rel="noreferrer" href="mailto:support@heykangaroo.com">
									support@heykangaroo.com
								</a>
								.
							</p>
							<Button
								className={styles.continueShopping}
								variant="outline-secondary"
								href={continueShoppingLink}
							>
								CONTINUE SHOPPING
							</Button>
						</div>
						<OrderSummary />
					</React.Fragment>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	order_id: state.orders.order_id,
	first_name: state.customer.shipping.first_name,
	order_status: state.orders.order_status,
});

export default connect(mapStateToProps, null)(Confirmation);
