import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setShippingAddress } from '~/redux/actions/customer';
import { createShipping, updateShipping } from '~/redux/actions/shipping';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';

import USStateSelect from '~/components/USStateSelect';
import CountrySelect from '~/components/CountrySelect';
import ShippingMethodSelect from '~/components/ShippingMethodSelect';
import { createSchema, max255, phone } from '~/utils/schema';
import { createLineItems } from '~/utils/_createLineItems';
import styles from './index.module.scss';

const schema = createSchema({
	first_name: max255.required('First name is required'),
	last_name: max255.required('Last name is required'),
	company: max255,
	address1: max255.required('Address is required'),
	address2: max255,
	city: max255.required('City is required'),
	state_or_province: max255.when('country_code', (country_code, schema) => {
		return schema.test({
			test: state_or_province =>
				country_code !== 'US' || (country_code === 'US' && state_or_province !== ''),
			message: 'State/Province is required.', //triggers when test is false
		});
	}),
	country_code: max255.required('Country is required'),
	postal_code: max255.when('country_code', {
		is: 'US',
		then: max255.test({
			test: value => value && value.length === 5 && value.replace(/[^0-9]+/g, '').length === 5,
			message: '5-digit Zip code required',
		}),
		otherwise: max255
			.test({
				test: value => value && value.length > 0 && value.replace(/[^0-9]+/g, '').length > 0,
				message: 'Zip code required',
			})
			.required(),
	}),
	phone: phone.required('Phone number is required'),
});

const ShippingForm = ({
	fields,
	setShippingAddress,
	line_items: { physical_items, digital_items },
	setSteps,
	consignment_id,
	isLoading,
	createShipping,
	updateShipping,
	available_shipping_options,
}) => {
	const [validForm, setValidForm] = useState(false);
	const [submittedForm, setSubmittedForm] = useState(false);
	const [shippingOption, setShippingOption] = useState(null);

	const formik = useFormik({
		validationSchema: schema,
		// force form errors on load
		initialErrors: { initialLoad: true },
		initialValues: {
			...fields,
			is_billing_same: true,
		},
		onSubmit: async values => {
			if (shippingOption === null) {
				setSubmittedForm(true);
				return;
			}
			try {
				await updateAddress();
				await setShippingAddress({
					...values,
					consignment_id: consignment_id,
					shipping_option_id: shippingOption,
				});
				setSteps();
			} catch (_error) {}
			setSubmittedForm(true);
		},
	});

	const updateAddress = useCallback(async () => {
		const shipping = {
			...formik.values,
			line_items: createLineItems(physical_items, digital_items),
		};
		if (!consignment_id) {
			await createShipping(shipping);
		} else {
			await updateShipping(shipping);
		}
	}, [formik.values, createShipping, updateShipping, physical_items, digital_items, consignment_id]);

	// custom form validation logic
	useEffect(() => {
		const hasErrors = formik.errors && Object.keys(formik.errors).length !== 0;
		if (formik.isValid && !hasErrors) {
			setValidForm(true);
		} else {
			setValidForm(false);
		}
	}, [formik.isValid, formik.errors]);

	// handle validation changes
	useEffect(() => {
		if (validForm) {
			updateAddress();
		} else {
			setShippingOption(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validForm]);

	return (
		<>
			<h4>SHIPPING ADDRESS</h4>
			<Form noValidate onSubmit={formik.handleSubmit}>
				<Form.Row>
					<Form.Group as={Col} lg="4">
						<Form.Label>First name</Form.Label>
						<Form.Control
							className="checkout-form"
							name="first_name"
							value={formik.values.first_name}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.first_name && !!formik.touched.first_name}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.first_name}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} lg="4">
						<Form.Label>Last name</Form.Label>
						<Form.Control
							className="checkout-form"
							name="last_name"
							required
							value={formik.values.last_name}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.last_name && !!formik.touched.last_name}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.last_name}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Company Name (optional)</Form.Label>
						<Form.Control
							className="checkout-form"
							name="company"
							required
							value={formik.values.company}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.company && !!formik.touched.company}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.company}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Phone Number</Form.Label>
						<Form.Control
							className="checkout-form"
							name="phone"
							required
							value={formik.values.phone}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.phone && !!formik.touched.phone}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Address</Form.Label>
						<Form.Control
							className="checkout-form"
							name="address1"
							required
							value={formik.values.address1}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.address1 && !!formik.touched.address1}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.address1}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Apartment/Suite/Building (Optional)</Form.Label>
						<Form.Control
							className="checkout-form"
							name="address2"
							required
							value={formik.values.address2}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.address2 && !!formik.touched.address2}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.address2}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>City</Form.Label>
						<Form.Control
							className="checkout-form"
							name="city"
							required
							value={formik.values.city}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.city && !!formik.touched.city}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Country</Form.Label>
						<CountrySelect
							sort
							visibleType="country"
							name="country_code"
							onChange={async e => {
								await formik.handleChange(e);
								formik.setFieldValue('state_or_province', '');
							}}
							value={formik.values.country_code}
							isInvalid={formik.touched.country_code && formik.errors.country_code}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.country_code}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					{formik.values.country_code === 'US' ? (
						<Form.Group as={Col} lg="5">
							<Form.Label>State/Province</Form.Label>
							<USStateSelect
								includeTerritories
								sort
								visibleType="state"
								valueType="state"
								name="state_or_province"
								onChange={formik.handleChange}
								value={formik.values.state_or_province}
								isInvalid={formik.touched.state_or_province && formik.errors.state_or_province}
							/>
							<Form.Control.Feedback type="invalid">
								{formik.errors.state_or_province}
							</Form.Control.Feedback>
						</Form.Group>
					) : (
						<Form.Group as={Col} lg="5">
							<Form.Label>State/Province (optional)</Form.Label>
							<Form.Control
								className="checkout-form"
								name="state_or_province"
								required
								value={formik.values.state_or_province}
								onChange={formik.handleChange}
								isInvalid={!!formik.errors.state_or_province && !!formik.touched.state_or_province}
							/>
							<Form.Control.Feedback type="invalid">
								{formik.errors.state_or_province}
							</Form.Control.Feedback>
						</Form.Group>
					)}
					<Form.Group as={Col} lg="3">
						<Form.Label>Zip Code</Form.Label>
						<Form.Control
							className="checkout-form"
							name="postal_code"
							required
							value={formik.values.postal_code}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.postal_code && !!formik.touched.postal_code}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.postal_code}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				{validForm && (
					<ShippingMethodSelect
						formik={formik}
						isLoading={isLoading}
						available_shipping_options={available_shipping_options}
						setShippingOption={setShippingOption}
					/>
				)}
				{isLoading ? (
					<Button disabled className={`${styles.submitButton} checkout-submit`}>
						<Spinner animation="border" variant="secondary" />
					</Button>
				) : (
					<Button type="submit" className={`${styles.submitButton} checkout-submit`}>
						CONTINUE
					</Button>
				)}
				{submittedForm && (!validForm || !shippingOption) && (
					<p className="invalid-msg">* Please fill all the fields</p>
				)}
			</Form>
		</>
	);
};

ShippingForm.propTypes = {
	// call when address is first valid to get shipping options
	createShipping: PropTypes.func,
	// call for each address update
	updateShipping: PropTypes.func,
	// call to submit final input
	setShippingAddress: PropTypes.func,
};

const mapStateToProps = state => ({
	fields: state.customer.shipping,
	line_items: state.cart.data.cart.line_items,
	available_shipping_options: state.shipping.available_shipping_options,
	consignment_id: state.shipping.consignment_id,
	isLoading: state.shipping.isLoading,
});

export default connect(mapStateToProps, { setShippingAddress, createShipping, updateShipping })(
	ShippingForm
);
