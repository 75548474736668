import React, { useMemo } from 'react';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const BasicInfo = ({ isLoading }) => {
	const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_KEY), []);
	return (
		<Elements stripe={stripePromise}>
			{isLoading ? <Spinner animation="border" role="status" /> : <CheckoutForm />}
		</Elements>
	);
};

const mapStateToProps = state => ({
	isLoading: state.orders.isLoading,
});

export default connect(mapStateToProps, null)(BasicInfo);
