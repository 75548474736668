import * as ActionType from '~/redux/constants/actionTypes';
import { logError } from '~/utils/_logError';
import { api } from '~/apis';

export const setCustomerEmail = data => dispatch => {
	dispatch({
		type: ActionType.CUSTOMER_UPDATE,
		payload: data,
	});
};

export const setShippingAddress = values => async (dispatch, getState) => {
	dispatch({ type: ActionType.CHECKOUT_LOADING });
	const { consignment_id, shipping_option_id, ...data } = values;
	const cart_id = getState().cart.data.cart.id;
	try {
		const shippingResult = await api.put('/checkout/store/shipping', {
			cart_id,
			consignment_id,
			shipping_option_id,
		});
		dispatch({
			type: ActionType.CART_UPDATE,
			payload: {
				grand_total: shippingResult.data.consignment.grand_total,
				tax_total: shippingResult.data.consignment.tax_total,
				shipping_cost_total_ex_tax: shippingResult.data.consignment.shipping_cost_total_ex_tax,
			},
		});
		dispatch({
			type: ActionType.CUSTOMER_UPDATE,
			payload: { shipping: data },
		});
		// billing address is disabled.
		// await dispatch(setBillingAddress(data));
	} catch (err) {
		logError(
			{ message: 'Failed to set shipping address' },
			`Failed to set Shipping address. ${JSON.stringify(values)}`
		);
		dispatch({ type: ActionType.CHECKOUT_ERROR });
		return false;
		// TODO: error log
	}
};

export const setBillingAddress = values => async (dispatch, getState) => {
	const email = getState().customer.email;
	const cart_id = getState().cart.data.cart.id;
	const address_id = getState().customer.billing.address_id;
	try {
		const requestBody = {
			...values,
			cart_id,
			email,
		};
		if (address_id.length > 0) {
			const res = await api.put('/checkout/store/billing', { ...requestBody, address_id });
			dispatch({
				type: ActionType.CUSTOMER_UPDATE,
				payload: { billing: { ...values, address_id: res.data.billing_address.id } },
			});
		} else {
			const res = await api.post('/checkout/store/billing', requestBody);
			dispatch({
				type: ActionType.CUSTOMER_UPDATE,
				payload: { billing: { ...values, address_id: res.data.billing_address.id } },
			});
		}
		return true;
	} catch (err) {
		logError(
			{ message: 'Failed to set biilling address' },
			`Failed to set biilling address. ${JSON.stringify(values)}`
		);
		dispatch({ type: ActionType.CHECKOUT_ERROR });
	}
};
