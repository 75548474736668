import React from 'react';
import styles from './index.module.scss';

function Header() {
	const storeUrl = process.env.REACT_APP_STORE_URL;

	return (
		<div className={styles.header}>
			<a href={storeUrl} className={styles.logo}>
				<img src="/images/logo.svg" width="150" alt="" />
			</a>
		</div>
	);
}

export default Header;
