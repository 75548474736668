import { logApi } from '~/apis';
import { version } from '../../package.json';

function detectBrowserName() {
	let navUserAgent = '';
	let browserName = '';
	let browserVersion = '';
	let tempNameOffset, tempVersionOffset;
	try {
		if (navigator !== undefined) {
			navUserAgent = navigator.userAgent;
			browserName = navigator.appName;
			if ((tempVersionOffset = navUserAgent.indexOf('Opera')) !== -1) {
				browserName = 'Opera';
				browserVersion = navUserAgent.split('Opera/')[1].split(' ')[0];
			} else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
				browserName = 'Microsoft Internet Explorer';
				browserVersion = navUserAgent.split('MSIE/')[1].split(' ')[0];
			} else if ((tempVersionOffset = navUserAgent.indexOf('Edge')) !== -1) {
				browserName = 'Edge';
				browserVersion = navUserAgent.split('Edge/')[1].split(' ')[0];
			} else if ((tempVersionOffset = navUserAgent.indexOf('Edg')) !== -1) {
				browserName = 'Edge';
				browserVersion = navUserAgent.split('Edg/')[1].split(' ')[0];
			} else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
				browserName = 'Chrome';
				browserVersion = navUserAgent.split('Chrome/')[1].split(' ')[0];
			} else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
				browserName = 'Safari';
				browserVersion = navUserAgent.split('Safari/')[1].split(' ')[0];
			} else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
				browserName = 'Firefox';
				browserVersion = navUserAgent.split('Firefox/')[1].split(' ')[0];
			} else if (
				(tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
				(tempVersionOffset = navUserAgent.lastIndexOf('/'))
			) {
				browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
			}
		}
		return `${browserName} v${browserVersion}`;
	} catch (err) {
		return 'server';
	}
}

export const logError = async (err, message, status) => {
	// eslint-disable-next-line no-console
	console.error('error', err);
	message = message || err.data.message || 'No Message';
	status = status || err.status || 404;
	const broswerName = detectBrowserName();
	try {
		const requestBody = {
			applicationName: `${process.env.REACT_APP_SITE_ENV}-checkout`,
			applicationVersion: version,
			errorMessage: message,
			statusCode: status,
			data: JSON.stringify(err.data || err.message),
			clientBrowser: broswerName,
		};

		await logApi.post('/errors', requestBody);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(`Failed to log error: ${err}`);
	}
};
