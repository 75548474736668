import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module';
import Analytics from './components/shared/Analytics';
import reportWebVitals from './reportWebVitals';
import '~/styles/global.scss';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GTM_ID || null,
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	<React.StrictMode>
		<Analytics />
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
