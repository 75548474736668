import axios from 'axios';

const bcHost = process.env.REACT_APP_BC_API_HOST_URL;

const api = axios.create({
	baseURL: `${bcHost}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

// This will do retry
api.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		return axios.request(error.config);
	}
);

export default api;
