/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { logError } from '~/utils/_logError';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || null;

const Analytics = () => {
	if (GA_TRACKING_ID) {
		ReactGA.initialize(GA_TRACKING_ID);
		const location = `${window.location.protocol}//${window.location.host}/`;
		useEffect(() => {
			ReactGA.set({ page: location }); // Update the user's current page
			ReactGA.pageview(location); // Record a pageview for the given page
		}, [location]);
	} else {
		logError('The key GA_TRACKING_ID is undefined.');
	}
	return null;
};

export default Analytics;
