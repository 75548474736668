import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const getMiddleware = () => {
	if (process.env.NODE_ENV === 'production') {
		return applyMiddleware(...[thunk]);
	} else {
		// Enable additional logging in non-production environments.
		return applyMiddleware(...[thunk], createLogger());
	}
};

const store = createStore(rootReducer, initialState, composeWithDevTools(getMiddleware()));

export default store;
