// Checkout
export const UPDATE_STEPS_OPEN_STATUS = 'UPDATE_STEPS_OPEN_STATUS';
export const UPDATE_STEP_EDITABLE = 'UPDATE_STEP_EDITABLE';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const UPDATE_SHOW_SHIPPING_OPTIONS = 'UPDATE_SHOW_SHIPPING_OPTIONS';
export const COPY_SHIPPING_ADDRESS_TO_BILLING = 'COPY_SHIPPING_TO_BILLING';
export const UPDATE_CUSTOMER_EMAIL = 'UPDATE_CUSTOMER_EMAIL';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const UPDATE_SHIPPING_BILLING = 'UPDATE_SHIPPING_BILLING';
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
export const CHECKOUT_LOADING = 'CHECKOUT_LOADING';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
// Cart
export const LOAD_CART = 'LOAD_CART';
export const CREATE_CART = 'CREATE_CART';
export const CREATED_CART = 'CREATED_CART';
export const CART_UPDATE = 'CART_UPDATE';
export const CART_FAILED = 'CART_FAILED';
export const UPDATE_CART_ADDRESS = 'UPDATE_CART_ADDRESS';

// Coupon
export const COUPON_DELETE = 'COUPON_DELETE';

// Shipping
export const LOAD_SHIPPING = 'LOAD_SHIPPING';
export const CREATED_SHPPING = 'CREATED_SHIPPING';
export const UPDATEED_SHPPING = 'UPDATED_SHIPPING';
export const SHIPPING_FAILED = 'SHIPPING_FAILED';

// Orders
export const ORDERS_LOAD = 'ORDERS_LOAD';
export const ORDERS_CREATED = 'ORDERS_CREATED';
export const ORDERS_COMPLETE = 'ORDERS_COMPLETE';
export const ORDERS_ERROR = 'ORDERS_ERROR';
export const ORDERS_LOAD_STRIPE = 'ORDERS_LOAD_STRIPE';

// Customer
export const CUSTOMER_LOAD = 'CUSTOMER_LOAD';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
