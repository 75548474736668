import * as ActionType from '~/redux/constants/actionTypes';
import { api } from '~/apis';
import { getChannelType } from '~/utils';
import { logError } from '~/utils/_logError';

export const createCart = (query, history) => async dispatch => {
	const { id, qty, sub, subqty } = query;
	try {
		const channel = getChannelType();
		const line_items = [];
		if ((id, qty)) {
			line_items.push({ product_id: id, quantity: qty });
		}
		if (sub) {
			line_items.push({ product_id: sub, quantity: subqty || 1 });
		}
		const requestBody = { channel, line_items };
		dispatch({
			type: ActionType.CREATE_CART,
		});
		if (line_items.length === 0) {
			throw new Error('cannot create cart without line items');
		}
		const res = await api.post('/cart/store/', requestBody);
		dispatch(loadCart(res.data));
	} catch (err) {
		logError(
			{ message: 'Failed to create cart' },
			`Failed to create cart. product id: ${id}, quantity: ${qty}, subscription: ${sub}, subscription quantity: ${subqty}`
		);
		history.push('/404');
	}
};

/**
 * Mock API function.
 */
export const loadCart =
	({ cartId }, history) =>
	async dispatch => {
		try {
			const res = await api.get(`/checkout/store/${cartId}`);
			dispatch({
				type: ActionType.LOAD_CART,
				payload: res.data.data,
			});
		} catch (err) {
			logError({ message: 'Failed to load cart' }, `Failed to load cart. cart id: ${cartId}`);
			history.push('/404');
		}
	};

export const addCoupon = coupon_code => async (dispatch, getState) => {
	const cart_id = getState().cart.data.cart.id;
	try {
		const res = await api.post(`/checkout/store/${cart_id}/coupons`, coupon_code);
		dispatch({
			type: ActionType.CART_UPDATE,
			payload: res.data.data,
		});
		return true;
	} catch (err) {
		logError(
			{ message: 'Failed to add coupon' },
			`Failed to add coupon. coupon_code: ${JSON.stringify(coupon_code)} cart id: ${cart_id}`
		);
		return false;
	}
};

export const deleteCoupon = coupon_code => async (dispatch, getState) => {
	const cart_id = getState().cart.data.cart.id;
	try {
		const res = await api.delete(`/checkout/store/${cart_id}/coupons/${coupon_code}`);
		dispatch({
			type: ActionType.CART_UPDATE,
			payload: res.data.data,
		});
	} catch (err) {
		logError(
			{ message: 'Failed to remove coupon' },
			`Failed to remove coupon. coupon_code: ${coupon_code} cart id: ${cart_id}`
		);
	}
};

export const updateCartAddress = () => async (dispatch, getState) => {
	const cartId = getState().cart.data.cart.id;
	const address = getState().customer.billing;
	address.email = getState().customer.email;
	const line_items = Object.values(getState().cart.data.cart.line_items).reduce(
		(acc, lineItemType) => {
			const items = lineItemType.map(item => ({ item_id: item.id, quantity: item.quantity }));
			acc.push(...items);
			return acc;
		},
		[]
	);
	const body = { address, line_items };
	try {
		const res = await api.post(`/checkout/store/${cartId}/address`, body);
		dispatch({
			type: ActionType.CART_UPDATE,
			payload: res.data.data,
		});
	} catch (err) {
		logError(
			{ message: 'Failed to update cart address' },
			`Failed to update cart_id "${cartId}" with address "${JSON.stringify(address)}"`
		);
	}
};
