import React from 'react';
import styles from './index.module.scss';
import Button from 'react-bootstrap/Button';
import { redirectUrl } from '~/utils';

const PageNotFound = () => {
	const url = redirectUrl();
	return (
		<div className={styles.errorContainer}>
			<div className={styles.errorWrap}>
				<h1>Page Not Found</h1>
				<p>The page you are looking for doesn't exist or has been moved.</p>
				<Button variant="warning" href={url}>
					GO BACK HOME
				</Button>
			</div>
		</div>
	);
};

export default PageNotFound;
