/* eslint-disable no-unused-vars */
import * as ActionType from '~/redux/constants/actionTypes';

const initialState = {
	available_shipping_options: [],
	consignment_id: '',
	isLoading: false,
	address_id: null,
};

const addressReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ActionType.LOAD_SHIPPING:
			return {
				...state,
				isLoading: true,
			};
		case ActionType.CREATED_SHPPING:
			return {
				...state,
				...payload,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default addressReducer;
