import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './jumping-roo.json';
const Loader = ({ height = 200, width = 200 }) => {
	return (
		<div className="loader">
			<Lottie loop animationData={lottieJson} play style={{ width: width, height: height }} />
		</div>
	);
};

export default Loader;
