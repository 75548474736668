import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setBillingAddress } from '~/redux/actions/customer';
import USStateSelect from '~/components/USStateSelect';
import CountrySelect from '~/components/CountrySelect';
import { Form, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { createSchema, max255, phone } from '~/utils/schema';

const schema = createSchema({
	first_name: max255.required('First name is required'),
	last_name: max255.required('Last name is required'),
	company: max255,
	address1: max255.required('Address is required'),
	address2: max255,
	city: max255.required('City is required'),
	state_or_province: max255.when('country_code', (country_code, schema) => {
		return schema.test({
			test: state_or_province =>
				country_code !== 'US' || (country_code === 'US' && state_or_province !== ''),
			message: 'State/Province is required.', //triggers when test is false
		});
	}),
	country_code: max255.required('Country is required'),
	postal_code: max255.required('Zip code is required'),
	phone: phone.required('Phone number is required'),
});
const BillingInfo = ({ fields, setBillingAddress, setSteps }) => {
	const [invalidForm, setInvalidForm] = useState(false);
	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			...fields,
		},
		onSubmit: async values => {
			await setBillingAddress({ ...values });
			setSteps();
		},
	});

	useEffect(() => {
		if (formik.submitCount > 0) {
			!formik.isValid ? setInvalidForm(true) : setInvalidForm(false);
		}
	}, [formik.values, formik.isValid, formik.submitCount]);
	return (
		<>
			<h4>BILLING ADDRESS</h4>
			<Form noValidate onSubmit={formik.handleSubmit}>
				<Form.Row>
					<Form.Group as={Col} lg="4">
						<Form.Label>First name</Form.Label>
						<Form.Control
							className="checkout-form"
							name="first_name"
							value={formik.values.first_name}
							onChange={formik.handleChange}
							isValid={formik.touched.first_name && !formik.errors.first_name}
							isInvalid={!!formik.errors.first_name && !!formik.touched.first_name}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.first_name}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} lg="4">
						<Form.Label>Last name</Form.Label>
						<Form.Control
							className="checkout-form"
							name="last_name"
							required
							value={formik.values.last_name}
							onChange={formik.handleChange}
							isValid={formik.touched.last_name && !formik.errors.last_name}
							isInvalid={!!formik.errors.last_name && !!formik.touched.last_name}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.last_name}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Company Name (optional)</Form.Label>
						<Form.Control
							className="checkout-form"
							name="company"
							required
							value={formik.values.company}
							onChange={formik.handleChange}
							isValid={formik.touched.company && !formik.errors.company}
							isInvalid={!!formik.errors.company && !!formik.touched.company}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.company}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Phone Number</Form.Label>
						<Form.Control
							className="checkout-form"
							name="phone"
							required
							value={formik.values.phone}
							onChange={formik.handleChange}
							isValid={formik.touched.phone && !formik.errors.phone}
							isInvalid={!!formik.errors.phone && !!formik.touched.phone}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Address</Form.Label>
						<Form.Control
							className="checkout-form"
							name="address1"
							required
							value={formik.values.address1}
							onChange={formik.handleChange}
							isValid={formik.touched.address1 && !formik.errors.address1}
							isInvalid={!!formik.errors.address1 && !!formik.touched.address1}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.address1}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Apartment/Suite/Building (Optional)</Form.Label>
						<Form.Control
							className="checkout-form"
							name="address2"
							required
							value={formik.values.address2}
							onChange={formik.handleChange}
							isValid={formik.touched.address2 && !formik.errors.address2}
							isInvalid={!!formik.errors.address2 && !!formik.touched.address2}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.address2}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>City</Form.Label>
						<Form.Control
							className="checkout-form"
							name="city"
							required
							value={formik.values.city}
							onChange={formik.handleChange}
							isValid={formik.touched.city && !formik.errors.city}
							isInvalid={!!formik.errors.city && !!formik.touched.city}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} lg={8}>
						<Form.Label>Country</Form.Label>
						<CountrySelect
							sort
							visibleType="country"
							name="country_code"
							onChange={formik.handleChange}
							value={formik.values.country_code}
							isInvalid={formik.touched.country_code && formik.errors.country_code}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.country_code}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					{formik.values.country_code === 'US' ? (
						<Form.Group as={Col} lg="5">
							<Form.Label>State/Province</Form.Label>
							<USStateSelect
								includeTerritories
								sort
								visibleType="state"
								valueType="state"
								name="state_or_province"
								onChange={formik.handleChange}
								value={formik.values.state_or_province}
								isInvalid={formik.touched.state_or_province && formik.errors.state_or_province}
							/>
							<Form.Control.Feedback type="invalid">
								{formik.errors.state_or_province}
							</Form.Control.Feedback>
						</Form.Group>
					) : (
						<Form.Group as={Col} lg="5">
							<Form.Label>State/Province (optional)</Form.Label>
							<Form.Control
								className="checkout-form"
								name="state_or_province"
								required
								value={formik.values.state_or_province}
								onChange={formik.handleChange}
								isInvalid={!!formik.errors.state_or_province && !!formik.touched.state_or_province}
							/>
							<Form.Control.Feedback type="invalid">
								{formik.errors.state_or_province}
							</Form.Control.Feedback>
						</Form.Group>
					)}
					<Form.Group as={Col} lg="3">
						<Form.Label>Zip Code</Form.Label>
						<Form.Control
							className="checkout-form"
							name="postal_code"
							required
							value={formik.values.postal_code}
							onChange={formik.handleChange}
							isValid={formik.touched.postal_code && !formik.errors.postal_code}
							isInvalid={!!formik.errors.postal_code && !!formik.touched.postal_code}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.postal_code}</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Button className="checkout-submit" type="submit">
					CONTINUE
				</Button>
				{invalidForm && <p className="invalid-msg">Please fill out the form completely</p>}
			</Form>
		</>
	);
};

BillingInfo.propTypes = {
	setBillingAddress: PropTypes.func,
};

const mapStateToProps = state => ({
	fields: state.customer.billing,
});

export default connect(mapStateToProps, { setBillingAddress })(BillingInfo);
