import { useState } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import styles from './Summary.module.scss';
import { connect } from 'react-redux';
import { addCoupon, deleteCoupon } from '~/redux/actions/cart';
import { useFormik } from 'formik';
import { createSchema, max255 } from '~/utils/schema';

const schema = createSchema({
	coupon_code: max255.required('Coupon is required'),
});

const Coupons = ({ addCoupon, deleteCoupon, coupons }) => {
	const [isLoading, setLoading] = useState(false);
	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			coupon_code: '',
		},
		onSubmit: async ({ coupon_code }) => {
			setLoading(true);
			const res = await addCoupon({ coupon_code });
			setLoading(false);
			if (!res) {
				formik.setErrors({ coupon_code: 'Enter a valid discount code' });
			} else {
				formik.resetForm({});
			}
		},
	});
	const [show, setShow] = useState(false);
	const removeCoupon = coupon_code => {
		deleteCoupon(coupon_code);
	};
	return (
		<>
			<tr>
				<td colSpan="2">
					<span onClick={() => setShow(!show)} className={styles.coupon}>
						Coupon/Gift Certificate
					</span>
				</td>
			</tr>
			{coupons.map(coupon => (
				<tr>
					<td>
						<span className={styles.couponName}>
							{coupon.code}{' '}
							<span className={styles.removeCoupon} onClick={() => removeCoupon(coupon.code)}>
								&#10006;
							</span>
						</span>
					</td>
					<td>
						<span>-${coupon.discounted_amount.toFixed(2)}</span>
					</td>
				</tr>
			))}
			{show && (
				<tr>
					<td colSpan="2">
						<div className={styles.couponsWrap}>
							<Form noValidate onSubmit={formik.handleSubmit}>
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="formPlaintextPassword">
											<Form.Control
												placeholder="Enter Coupons"
												name="coupon_code"
												value={formik.values.coupon_code}
												onChange={formik.handleChange}
												isInvalid={!!formik.errors.coupon_code && !!formik.touched.coupon_code}
											/>
											<Form.Control.Feedback type="invalid">{formik.errors.coupon_code}</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col xs="auto">
										{isLoading ? (
											<Button disabled variant="outline-dark" type="submit">
												<Spinner animation="border" variant="secondary" />
											</Button>
										) : (
											<Button variant="outline-dark" type="submit">
												Apply
											</Button>
										)}
									</Col>
								</Row>
							</Form>
						</div>
					</td>
				</tr>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	coupons: state.cart.data.cart.coupons,
});

export default connect(mapStateToProps, { addCoupon, deleteCoupon })(Coupons);
