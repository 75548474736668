import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { completeOrder } from '~/redux/actions/orders';
import { withRouter } from 'react-router-dom';
import { logError } from '~/utils/_logError';

import styles from './CheckoutForm.module.scss';

const CheckoutForm = ({ intent, completeOrder, history, stripeLoading }) => {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const [disabled, setDisabled] = useState(true);
	const stripe = useStripe();
	const elements = useElements();
	const cardStyle = {
		style: {
			base: {
				color: '#32325d',
				fontFamily: 'Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#32325d',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
		hidePostalCode: true,
	};

	const handleChange = async event => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : '');
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setProcessing(true);

		const payload = await stripe.confirmCardPayment(intent, {
			payment_method: {
				card: elements.getElement(CardElement),
			},
		});

		if (payload.error) {
			logError(
				{ message: 'Failed to submit payment' },
				`Failed to submit payment. stripe error: ${payload.error.message}`
			);
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
		} else {
			setError(null);
			setProcessing(false);
			setSucceeded(true);
			completeOrder(history);
		}
	};

	return (
		<form id="payment-form" className={styles.stripeForm} onSubmit={handleSubmit}>
			<CardElement className={styles.stripeInput} options={cardStyle} onChange={handleChange} />
			<button
				className={styles.stripeButton}
				disabled={processing || disabled || succeeded || stripeLoading}
				id="submit"
			>
				<span id="button-text">{processing || stripeLoading ? 'Processing...' : 'PLACE ORDER'}</span>
			</button>
			<p className={styles.terms}>
				By submitting this form you agree to the Kangaroo
				<a href="https://info.heykangaroo.com/legal" rel="noreferrer" target="_blank">
					{' '}
					terms of service{' '}
				</a>
				and authorize Kangaroo to charge your credit/debit card that we have on file each renewal period
				(monthly/yearly). You may Cancel your subscription any time before the automatic renewal period
				by contacting
				<a href="mailto:support@heykangaroo.com" rel="noreferrer" target="_blank">
					{' '}
					support@heykangaroo.com{' '}
				</a>
				or on the Kangaroo app.
			</p>
			{/* Show any error that happens when processing the payment */}
			{error && (
				<div className={styles.cardError} role="alert">
					{error}
				</div>
			)}
		</form>
	);
};

const mapStateToProps = state => ({
	intent: state.orders.intent,
	stripeLoading: state.orders.stripeLoading,
});

export default connect(mapStateToProps, { completeOrder })(withRouter(CheckoutForm));
