import * as ActionType from '~/redux/constants/actionTypes';

const initialState = {
	isLoading: false,
	email: '',
	shipping: {
		first_name: '',
		last_name: '',
		company: '',
		phone: '',
		address1: '',
		address2: '',
		city: '',
		state_or_province: '',
		country_code: '',
		postal_code: '',
	},
	billing: {
		address_id: '',
		first_name: '',
		last_name: '',
		company: '',
		phone: '',
		address1: '',
		address2: '',
		city: '',
		state_or_province: '',
		country_code: '',
		postal_code: '',
	},
};

const checkoutReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ActionType.CUSTOMER_UPDATE:
			return {
				...state,
				...payload,
			};
		default:
			return state;
	}
};

export default checkoutReducer;
