import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Store from './routes/home';
import reduxStore from '~/redux/store';
import PageNotFound from '~/routes/error/PageNotFound';
import confirmation from '~/routes/confirmation';
import VendorScripts from './utils/vendorScripts';

function App() {
	const { pathname, search: searchQuery } = window.location;

	useEffect(() => {
		const heapAnalyticsScript = document.createElement('script');
		heapAnalyticsScript.innerHTML = VendorScripts.heapAnalytics;
		document.head.appendChild(heapAnalyticsScript);
	}, []);

	return (
		<Provider store={reduxStore}>
			<BrowserRouter>
				<div className="App">
					<Switch>
						{/* remove tailing slash for all url */}
						<Redirect from="/:url*(/+)" to={`${pathname.replace(/\/+$/, '')}${searchQuery}`} />
						<Route exact path={'/'} component={Store} />
						<Route exact path={['/cart', '/cart/:cartId']} component={Store} />
						<Route exact path={['/subscription']} component={Store} />
						<Route exact path={['/confirmation', '/confirmation/:cartId']} component={confirmation} />
						<Route path="/">
							<PageNotFound />
						</Route>
					</Switch>
				</div>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
