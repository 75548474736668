import React from 'react';
import PropTypes from 'prop-types';
import Loader from '~/components/Loader';
import styles from './Summary.module.scss';

// Take the first image found and return it.
const getImageUrl = (physicalItems, digitalItems) => {
	for (let item of physicalItems) {
		if (item.image_url) return item.image_url;
	}
	for (let item of digitalItems) {
		if (item.image_url) return item.image_url;
	}
	return false;
};

function BriefSummary({
	subtotal_ex_tax,
	grand_total,
	physicalItems,
	digitalItems,
	setShowDetailsOnMobile,
	isLoading,
}) {
	const imageUrl = getImageUrl(physicalItems, digitalItems);
	const totalItems =
		physicalItems.reduce((a, b) => a + b.quantity, 0) +
		digitalItems.reduce((a, b) => a + b.quantity, 0);
	return isLoading ? (
		<div className={styles.wrap}>
			<div>
				<h3>Order Summary</h3>
			</div>
			<div>
				<Loader height={150} width={150} />
			</div>
		</div>
	) : (
		<div className={styles.wrap}>
			<div>
				<h3>Order Summary</h3>
			</div>
			<div className={styles.briefSummary}>
				<img src={imageUrl} alt="" />
				<div>
					<span className={styles.totalItems}>
						{totalItems && <>{`${totalItems} ` + (totalItems > 1 ? 'items' : 'item')}</>}
					</span>
					<br />
					<span className={styles.showDetails} onClick={() => setShowDetailsOnMobile(true)}>
						Show Details
					</span>
				</div>
				<div className={styles.totalAmount}>
					${grand_total !== null ? grand_total.toFixed(2) : subtotal_ex_tax.toFixed(2)}
				</div>
			</div>
		</div>
	);
}

BriefSummary.propTypes = {
	amount: PropTypes.number,
	physicalItems: PropTypes.array,
	digitalItems: PropTypes.array,
	setShowDetailsOnMobile: PropTypes.func,
};

export default BriefSummary;
