export function getSearchQuery() {
	if (!window.location.search) {
		return {};
	}

	const query = {};

	const params = new URLSearchParams(window.location.search);
	for (const [key, value] of params.entries()) {
		query[key] = value;
	}
	return query;
}

export const getChannelType = () => {
	const siteEnv = process.env.REACT_APP_SITE_ENV;

	switch (siteEnv) {
		case 'local':
			return 'local';
		case 'preview':
			return 'preview_store';
		case 'dev':
			return 'dev_store';
		case 'staging':
			return 'staging_store';
		case 'production':
			return 'store';
		default:
			return null;
	}
};

export const debounce = (func, timeout = 300) => {
	// eslint-disable-next-line no-unused-vars
	let timer;
	return (...args) => {
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
};

export const redirectUrl = () => {
	const siteEnv = process.env.REACT_APP_SITE_ENV;

	switch (siteEnv) {
		case 'preview':
			return 'https://preview.roopirates.com';
		case 'dev':
			return 'https://dev.roopirates.com';
		case 'staging':
			return 'https://roopirates.com';
		case 'production':
			return 'https://heykangaroo.com/';
		default:
			return 'https://dev.roopirates.com';
	}
};

export const isSubscriptionOnly = state => {
	return state.customer.shipping.first_name === '';
};
