import { string, object, boolean } from 'yup';

// simple function so we don't have to import yup in both places
export function createSchema(obj) {
	return object(obj);
}

/**
 * Re-usable yup validation schemas
 */

export const bool = boolean();

/**
 * A lot of our string max length is 255 so we can re-use this
 */
export const max255 = string()
	.ensure()
	.trim()
	.max(255, 'Max 255 characters allowed for this field');

/**
 * Validation for email
 */
export const emailRequired = max255
	.required('Email is required')
	.email('Please enter a valid email');

/**
 * Validate a phone number
 * ensures that there are between 10 and 13 digits (ignoring all other characters)
 *
 * without country code:
 * 10 digits: XXX-XXX-XXXX
 *
 * with country code:
 * 13 digits: XXX-XXX-XXX-XXXX
 */
export const phone = max255.test('phone', 'Please enter a valid phone number', value => {
	if (value.length === 0) return true;
	// remove all non-numbers from string
	const digits = value.replace(/[^0-9]+/g, '');
	// ensure phone is between 10 and 12 digits
	return digits.length >= 10 && digits.length <= 13;
});
