import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DetailedSummay from './DetailedSummay';
import BriefSummay from './BriefSummary';

import styles from './OrderSummary.module.scss';

function OrderSummary({ cartData, isLoading }) {
	const [showDetailsOnMobile, setShowDetailsOnMobile] = useState(false);
	const { subtotal_ex_tax, grand_total, cart, tax_total, shipping_cost_total_ex_tax } = cartData;
	const { digital_items, physical_items } = cart.line_items;
	return (
		<div className={styles.orderSummary}>
			<div className={`${styles.detailed} ${showDetailsOnMobile ? styles.show : ''}`}>
				<DetailedSummay
					subtotal_ex_tax={subtotal_ex_tax}
					grand_total={grand_total}
					tax_total={tax_total}
					shipping_cost_total_ex_tax={shipping_cost_total_ex_tax}
					physicalItems={physical_items}
					digitalItems={digital_items}
					isLoading={isLoading}
					discounts={cart.discounts}
				/>
			</div>
			<div className={`${styles.brief} ${showDetailsOnMobile ? styles.hide : ''}`}>
				<BriefSummay
					subtotal_ex_tax={subtotal_ex_tax}
					grand_total={grand_total}
					physicalItems={physical_items}
					digitalItems={digital_items}
					isLoading={isLoading}
					setShowDetailsOnMobile={setShowDetailsOnMobile}
				/>
			</div>
		</div>
	);
}

OrderSummary.propTypes = {
	data: PropTypes.object,
};

const mapStateToProps = state => ({
	cartData: state.cart.data,
	isLoading: state.cart.isLoading,
});

export default connect(mapStateToProps, null)(OrderSummary);
