import React from 'react';
import PropTypes from 'prop-types';
import ItemCard from './ItemCard';
import Coupons from './Coupons';
import Loader from '~/components/Loader';
import styles from './Summary.module.scss';

function DetailedSummay({
	subtotal_ex_tax,
	grand_total,
	shipping_cost_total_ex_tax,
	tax_total,
	physicalItems,
	digitalItems,
	isLoading,
	discounts,
}) {
	let discount_amount = !isLoading ? discounts.reduce((a, b) => a + b.discounted_amount, 0) : null;

	const totalItems =
		physicalItems.reduce((a, b) => a + b.quantity, 0) +
		digitalItems.reduce((a, b) => a + b.quantity, 0);
	const hasPhysicalItems = physicalItems.length > 0;
	return isLoading ? (
		<div className={styles.wrap}>
			<div>
				<h3>Order Summary</h3>
			</div>
			<div>
				<Loader />
			</div>
		</div>
	) : (
		<div className={styles.wrap}>
			<div>
				<h3>Order Summary</h3>
			</div>
			<div>
				<table className={styles.alignTop}>
					<tbody>
						<tr>
							<td colSpan="2">
								{totalItems && <>{`${totalItems} ` + (totalItems > 1 ? 'items' : 'item')}</>}
							</td>
						</tr>
						{hasPhysicalItems &&
							physicalItems.map(item => (
								<tr key={item.id}>
									<td>
										<ItemCard imageUrl={item.image_url} itemName={item.name} quantity={item.quantity} />
									</td>
									<td>${item.extended_list_price.toFixed(2)}</td>
								</tr>
							))}
						{digitalItems.length > 0 &&
							digitalItems.map(item => (
								<tr key={item.id}>
									<td>
										<ItemCard imageUrl={item.image_url} itemName={item.name} quantity={item.quantity} />
									</td>
									<td>${item.extended_sale_price.toFixed(2)}</td>
								</tr>
							))}
						<tr>
							<td>
								<p className={styles.disclaimer}>
									Products purchased with subscription plan renew annually; cancel anytime ahead of renewal.
									See product page for plan pricing.
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div>
				<table>
					<tbody>
						{discount_amount > 0 && (
							<tr>
								<td>
									<span className={styles.red}>Saving</span>
								</td>
								<td>
									<span className={styles.red}>
										-{discount_amount > 0 ? `$${discount_amount.toFixed(2)}` : '--'}
									</span>
								</td>
							</tr>
						)}
						<tr>
							<td>Subtotal</td>
							<td>{subtotal_ex_tax > 0 ? `$${subtotal_ex_tax.toFixed(2)}` : '--'}</td>
						</tr>
						{hasPhysicalItems && (
							<tr>
								<td>Shipping</td>
								<td>
									{shipping_cost_total_ex_tax > 0 ? `$${shipping_cost_total_ex_tax.toFixed(2)}` : '--'}
								</td>
							</tr>
						)}
						<tr>
							<td>Tax</td>
							<td>{tax_total > 0 ? `$${tax_total.toFixed(2)}` : '--'}</td>
						</tr>

						<Coupons />
					</tbody>
				</table>
			</div>
			<div>
				<table>
					<tbody>
						<tr>
							<td>Total(USD)</td>
							<td>
								<em>{grand_total !== null ? `$${grand_total.toFixed(2)}` : '--'}</em>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

DetailedSummay.propTypes = {
	amount: PropTypes.number,
	physicalItems: PropTypes.array,
	digitalItems: PropTypes.array,
};

export default DetailedSummay;
