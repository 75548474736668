import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadCart, createCart } from '~/redux/actions/cart';
import Header from '~/components/Header';
import Checkout from './Checkout';
import SubscriptionCheckout from './SubscriptionCheckout';
import OrderSummary from '~/components/OrderSummary';
import { getSearchQuery } from '~/utils';
import styles from './index.module.scss';

const createCheckout = match => {
	return match.path.includes('/subscription') ? <SubscriptionCheckout /> : <Checkout />;
};

const Home = ({ createCart, loadCart, match, order_status, history }) => {
	const query = getSearchQuery();
	useEffect(() => {
		if (order_status) {
			history.push('/confirmation');
		} else {
			if (match.path === '/cart/:cartId' && match.params.cartId) {
				loadCart(match.params, history);
			}
			if ((match.path === '/' || match.path === '/subscription') && !!query) {
				createCart(query, history);
			}
		}
	}, [createCart, loadCart, query, match, order_status, history]);

	const checkout = createCheckout(match);
	return (
		<div className={styles.storePage}>
			<Header />
			<div className={styles.main}>
				<div className={styles.container}>
					<React.Fragment>
						{checkout}
						<OrderSummary />
					</React.Fragment>
				</div>
			</div>
		</div>
	);
};

Home.propTypes = {
	loadCart: PropTypes.func,
	createCart: PropTypes.func,
};

const mapStateToProps = state => ({
	order_status: state.orders.order_status,
});

export default connect(mapStateToProps, { loadCart, createCart })(Home);
