import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setCustomerEmail } from '~/redux/actions/customer';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { createSchema, emailRequired } from '~/utils/schema';

const schema = createSchema({
	email: emailRequired,
});

function CustomerEmail({ fields, setCustomerEmail, setSteps }) {
	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			email: fields.email,
		},
		onSubmit: ({ email }) => {
			setCustomerEmail({ email });
			setSteps();
		},
	});
	return (
		<Form noValidate onSubmit={formik.handleSubmit}>
			<Row>
				<Col>
					<Form.Label>
						<span>Email Address</span>
					</Form.Label>
				</Col>
			</Row>
			<Row>
				<Form.Group as={Col} lg={7} controlId="customerEmail">
					<Form.Control
						className="checkout-form"
						placeholder="Email"
						type="email"
						name="email"
						autoFocus
						required
						onChange={formik.handleChange}
						value={formik.values.email}
						isInvalid={!!formik.errors.email && !!formik.touched.email}
					/>
					<Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
				</Form.Group>
				<Col>
					<Button className="checkout-submit" type="submit">
						CONTINUE
					</Button>
				</Col>
			</Row>
		</Form>
	);
}

CustomerEmail.propTypes = {
	fields: PropTypes.object,
	setCustomerEmail: PropTypes.func,
	setSteps: PropTypes.func,
};

const mapStateToProps = state => ({
	fields: state.customer,
});

export default connect(mapStateToProps, { setCustomerEmail })(CustomerEmail);
