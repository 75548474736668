import React from 'react';
import { Form } from 'react-bootstrap';

export const Countries = {
	US: 'United States',

	// Commented out international shipping for future release.
	// AF: 'Afghanistan',
	// AX: 'Åland Islands',
	// AL: 'Albania',
	// DZ: 'Algeria',
	// AS: 'American Samoa',
	// AD: 'Andorra',
	// AO: 'Angola',
	// AI: 'Anguilla',
	// AQ: 'Antarctica',
	// AG: 'Antigua and Barbuda',
	// AR: 'Argentina',
	// AM: 'Armenia',
	// AW: 'Aruba',
	// AU: 'Australia',
	// AT: 'Austria',
	// AZ: 'Azerbaijan',
	// BS: 'Bahamas',
	// BH: 'Bahrain',
	// BD: 'Bangladesh',
	// BB: 'Barbados',
	// BY: 'Belarus',
	// BE: 'Belgium',
	// BZ: 'Belize',
	// BJ: 'Benin',
	// BM: 'Bermuda',
	// BT: 'Bhutan',
	// BO: 'Bolivia',
	// BA: 'Bosnia and Herzegovina',
	// BW: 'Botswana',
	// BV: 'Bouvet Island',
	// BR: 'Brazil',
	// IO: 'British Indian Ocean Territory',
	// BN: 'Brunei Darussalam',
	// BG: 'Bulgaria',
	// BF: 'Burkina Faso',
	// BI: 'Burundi',
	// KH: 'Cambodia',
	// CM: 'Cameroon',
	// CA: 'Canada',
	// CV: 'Cape Verde',
	// KY: 'Cayman Islands',
	// CF: 'Central African Republic',
	// TD: 'Chad',
	// CL: 'Chile',
	// CN: 'China',
	// CX: 'Christmas Island',
	// CC: 'Cocos (Keeling) Islands',
	// CO: 'Colombia',
	// KM: 'Comoros',
	// CG: 'Congo',
	// CD: 'Congo, The Democratic Republic of the',
	// CK: 'Cook Islands',
	// CR: 'Costa Rica',
	// CI: "Cote D'Ivoire",
	// HR: 'Croatia',
	// CU: 'Cuba',
	// CY: 'Cyprus',
	// CZ: 'Czech Republic',
	// DK: 'Denmark',
	// DJ: 'Djibouti',
	// DM: 'Dominica',
	// DO: 'Dominican Republic',
	// EC: 'Ecuador',
	// EG: 'Egypt',
	// SV: 'El Salvador',
	// GQ: 'Equatorial Guinea',
	// ER: 'Eritrea',
	// EE: 'Estonia',
	// ET: 'Ethiopia',
	// FK: 'Falkland Islands (Malvinas)',
	// FO: 'Faroe Islands',
	// FJ: 'Fiji',
	// FI: 'Finland',
	// FR: 'France',
	// GF: 'French Guiana',
	// PF: 'French Polynesia',
	// TF: 'French Southern Territories',
	// GA: 'Gabon',
	// GM: 'Gambia',
	// GE: 'Georgia',
	// DE: 'Germany',
	// GH: 'Ghana',
	// GI: 'Gibraltar',
	// GR: 'Greece',
	// GL: 'Greenland',
	// GD: 'Grenada',
	// GP: 'Guadeloupe',
	// GU: 'Guam',
	// GT: 'Guatemala',
	// GG: 'Guernsey',
	// GN: 'Guinea',
	// GW: 'Guinea-Bissau',
	// GY: 'Guyana',
	// HT: 'Haiti',
	// HM: 'Heard Island and Mcdonald Islands',
	// VA: 'Holy See (Vatican City State)',
	// HN: 'Honduras',
	// HK: 'Hong Kong',
	// HU: 'Hungary',
	// IS: 'Iceland',
	// IN: 'India',
	// ID: 'Indonesia',
	// IR: 'Iran, Islamic Republic Of',
	// IQ: 'Iraq',
	// IE: 'Ireland',
	// IM: 'Isle of Man',
	// IL: 'Israel',
	// IT: 'Italy',
	// JM: 'Jamaica',
	// JP: 'Japan',
	// JE: 'Jersey',
	// JO: 'Jordan',
	// KZ: 'Kazakhstan',
	// KE: 'Kenya',
	// KI: 'Kiribati',
	// KP: "Korea, Democratic People'S Republic of",
	// KR: 'Korea, Republic of',
	// KW: 'Kuwait',
	// KG: 'Kyrgyzstan',
	// LA: "Lao People'S Democratic Republic",
	// LV: 'Latvia',
	// LB: 'Lebanon',
	// LS: 'Lesotho',
	// LR: 'Liberia',
	// LY: 'Libyan Arab Jamahiriya',
	// LI: 'Liechtenstein',
	// LT: 'Lithuania',
	// LU: 'Luxembourg',
	// MO: 'Macao',
	// MK: 'Macedonia, The Former Yugoslav Republic of',
	// MG: 'Madagascar',
	// MW: 'Malawi',
	// MY: 'Malaysia',
	// MV: 'Maldives',
	// ML: 'Mali',
	// MT: 'Malta',
	// MH: 'Marshall Islands',
	// MQ: 'Martinique',
	// MR: 'Mauritania',
	// MU: 'Mauritius',
	// YT: 'Mayotte',
	// MX: 'Mexico',
	// FM: 'Micronesia, Federated States of',
	// MD: 'Moldova, Republic of',
	// MC: 'Monaco',
	// MN: 'Mongolia',
	// MS: 'Montserrat',
	// MA: 'Morocco',
	// MZ: 'Mozambique',
	// MM: 'Myanmar',
	// NA: 'Namibia',
	// NR: 'Nauru',
	// NP: 'Nepal',
	// NL: 'Netherlands',
	// AN: 'Netherlands Antilles',
	// NC: 'New Caledonia',
	// NZ: 'New Zealand',
	// NI: 'Nicaragua',
	// NE: 'Niger',
	// NG: 'Nigeria',
	// NU: 'Niue',
	// NF: 'Norfolk Island',
	// MP: 'Northern Mariana Islands',
	// NO: 'Norway',
	// OM: 'Oman',
	// PK: 'Pakistan',
	// PW: 'Palau',
	// PS: 'Palestinian Territory, Occupied',
	// PA: 'Panama',
	// PG: 'Papua New Guinea',
	// PY: 'Paraguay',
	// PE: 'Peru',
	// PH: 'Philippines',
	// PN: 'Pitcairn',
	// PL: 'Poland',
	// PT: 'Portugal',
	// PR: 'Puerto Rico',
	// QA: 'Qatar',
	// RE: 'Reunion',
	// RO: 'Romania',
	// RU: 'Russian Federation',
	// RW: 'RWANDA',
	// SH: 'Saint Helena',
	// KN: 'Saint Kitts and Nevis',
	// LC: 'Saint Lucia',
	// PM: 'Saint Pierre and Miquelon',
	// VC: 'Saint Vincent and the Grenadines',
	// WS: 'Samoa',
	// SM: 'San Marino',
	// ST: 'Sao Tome and Principe',
	// SA: 'Saudi Arabia',
	// SN: 'Senegal',
	// CS: 'Serbia and Montenegro',
	// SC: 'Seychelles',
	// SL: 'Sierra Leone',
	// SG: 'Singapore',
	// SK: 'Slovakia',
	// SI: 'Slovenia',
	// SB: 'Solomon Islands',
	// SO: 'Somalia',
	// ZA: 'South Africa',
	// GS: 'South Georgia and the South Sandwich Islands',
	// ES: 'Spain',
	// LK: 'Sri Lanka',
	// SD: 'Sudan',
	// SR: 'Suriname',
	// SJ: 'Svalbard and Jan Mayen',
	// SZ: 'Swaziland',
	// SE: 'Sweden',
	// CH: 'Switzerland',
	// SY: 'Syrian Arab Republic',
	// TW: 'Taiwan, Province of China',
	// TJ: 'Tajikistan',
	// TZ: 'Tanzania, United Republic of',
	// TH: 'Thailand',
	// TL: 'Timor-Leste',
	// TG: 'Togo',
	// TK: 'Tokelau',
	// TO: 'Tonga',
	// TT: 'Trinidad and Tobago',
	// TN: 'Tunisia',
	// TR: 'Turkey',
	// TM: 'Turkmenistan',
	// TC: 'Turks and Caicos Islands',
	// TV: 'Tuvalu',
	// UG: 'Uganda',
	// UA: 'Ukraine',
	// AE: 'United Arab Emirates',
	// GB: 'United Kingdom',
	// UM: 'United States Minor Outlying Islands',
	// UY: 'Uruguay',
	// UZ: 'Uzbekistan',
	// VU: 'Vanuatu',
	// VE: 'Venezuela',
	// VN: 'Viet Nam',
	// VG: 'Virgin Islands, British',
	// VI: 'Virgin Islands, U.S.',
	// WF: 'Wallis and Futuna',
	// EH: 'Western Sahara',
	// YE: 'Yemen',
	// ZM: 'Zambia',
	// ZW: 'Zimbabwe',
};

function Option({ code, name, valueType, visibleType }) {
	const finalValue = valueType === 'code' ? code : name;
	const finalText = visibleType === 'code' ? code : name;
	return <option value={finalValue}>{finalText}</option>;
}

function USStateSelect({
	includeTerritories,
	includeArmedForces,
	sort,
	valueType,
	visibleType,
	...passThrouhProps
}) {
	let countries = Countries;
	if (sort) {
		countries = Object.keys(countries).reduce((acc, key) => {
			acc[key] = countries[key];
			return acc;
		}, {});
	}

	return (
		<Form.Control as="select" {...passThrouhProps} className="checkout-form">
			<option value="">--</option>
			{Object.keys(countries).map(key => {
				return (
					<Option
						key={key}
						code={key}
						name={countries[key]}
						valueType={valueType}
						visibleType={visibleType}
					/>
				);
			})}
		</Form.Control>
	);
}

USStateSelect.defaultProps = {
	sort: false,
	valueType: 'code',
	visibleType: 'code',
};

export default USStateSelect;
