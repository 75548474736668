import * as ActionType from '~/redux/constants/actionTypes';
import { api } from '~/apis';
import { logError } from '~/utils/_logError';

/**
 * Create shipping with shipping address
 * @param {*} formData
 * @returns
 */
export const createShipping = formData => async (dispatch, getState) => {
	const email = getState().customer.email;
	const cart_id = getState().cart.data.cart.id;
	const { line_items, ...addressData } = formData;
	dispatch({ type: ActionType.LOAD_SHIPPING });
	try {
		const res = await api.post(`/checkout/store/${cart_id}/address`, {
			address: { ...addressData, email },
			line_items,
		});
		const data = res.data.data.consignments[0];
		const address_id = res.data.data.billing_address.id;
		dispatch({
			type: ActionType.CREATED_SHPPING,
			payload: {
				available_shipping_options: data.available_shipping_options,
				consignment_id: data.id,
				address_id,
			},
		});
	} catch (err) {
		logError({ message: 'Failed to create consignment' }, `Failed to create consignment. ${cart_id}`);
	}
};

/**
 * Update existing shipping
 * @param {*} data
 * @returns
 */
export const updateShipping = data => async (dispatch, getState) => {
	const email = getState().customer.email;
	const cart_id = getState().cart.data.cart.id;
	const address_id = getState().shipping.address_id;
	const consignment_id = getState().shipping.consignment_id;
	const { line_items, ...addressData } = data;
	dispatch({ type: ActionType.LOAD_SHIPPING });
	try {
		const res = await api.put(`/checkout/store/${cart_id}/address`, {
			address: {
				...addressData,
				email,
			},
			line_items,
			address_id,
			consignment_id,
		});
		const data = res.data.data.consignments[0];
		dispatch({
			type: ActionType.CREATED_SHPPING,
			payload: {
				available_shipping_options: data.available_shipping_options,
				consignment_id: data.id,
			},
		});
	} catch (err) {
		logError({ message: 'Failed to update shipping' }, `Failed to update shipping. ${cart_id}`);
	}
};

// export const setShippingAddress = values => async (dispatch, getState) => {
//	 dispatch({ type: ActionType.CHECKOUT_LOADING });
//	 const { consignment_id, shipping_option_id } = values;
//	 const cart_id = getState().cart.data.cart.id;
//	 try {
//		 const shippingResult = await api.put('/checkout/store/shipping', {
//			 cart_id,
//			 consignment_id,
//			 shipping_option_id,
//		 });
//		 dispatch({
//			 type: ActionType.CART_UPDATE,
//			 payload: {
//				 grand_total: shippingResult.data.consignment.grand_total,
//				 tax_total: shippingResult.data.consignment.tax_total,
//				 shipping_cost_total_ex_tax: shippingResult.data.consignment.shipping_cost_total_ex_tax,
//			 },
//		 });

//		 // commented out for the use of updating billing address in the future.
//		 // if (is_billing_same) {
//		 //	 dispatch(setBillingAddress(data));
//		 //	 return true;
//		 // } else {
//		 //	 dispatch({
//		 //		 type: ActionType.UPDATE_SHIPPING_ADDRESS,
//		 //		 payload: data,
//		 //	 });
//		 //	 return true;
//		 // }
//	 } catch (err) {
//		 logError(
//			 { message: 'Failed to set shipping address' },
//			 `Failed to set Shipping address. ${JSON.stringify(values)}`
//		 );
//		 dispatch({ type: ActionType.CHECKOUT_ERROR });
//		 return false;
//		 // TODO: error log
//	 }
// };

// export const setBillingAddress = values => async (dispatch, getState) => {
//	 const email = getState().customer.email;
//	 const cart_id = getState().cart.data.cart.id;
//	 const address_id = getState().checkout.customer.address_id;
//	 try {
//		 const requestBody = {
//			 ...values,
//			 cart_id,
//			 email,
//		 };
//		 if (address_id.length > 0) {
//			 const res = await api.put('/checkout/store/billing', { ...requestBody, address_id });
//			 dispatch({
//				 type: ActionType.UPDATE_BILLING_ADDRESS,
//				 payload: { ...values, address_id: res.data.billing_address.id },
//			 });
//		 } else {
//			 const res = await api.post('/checkout/store/billing', requestBody);
//			 dispatch({
//				 type: ActionType.UPDATE_SHIPPING_BILLING,
//				 payload: { ...values, address_id: res.data.billing_address.id },
//			 });
//		 }
//	 } catch (err) {
//		 logError(
//			 { message: 'Failed to set biilling address' },
//			 `Failed to set biilling address. ${JSON.stringify(values)}`
//		 );
//		 dispatch({ type: ActionType.CHECKOUT_ERROR });
//	 }
// };
