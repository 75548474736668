export const createLineItems = (physical_items, digital_items) => {
	let line_items = [];
	physical_items.forEach(element => {
		line_items.push({ quantity: element.quantity, item_id: element.product_id });
	});
	digital_items.forEach(element => {
		line_items.push({ quantity: element.quantity, item_id: element.product_id });
	});
	return line_items;
};
