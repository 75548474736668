import React from 'react';
import PropTypes from 'prop-types';
import styles from './ItemCard.module.scss';

function ItemCard({ imageUrl, itemName, quantity }) {
	return (
		<div className={styles.summaryItemCard}>
			<div className={styles.imageWrap}>
				<img src={imageUrl} alt="" />
			</div>
			<div>
				<h4>
					{quantity > 1 && <span>{quantity} x </span>}
					{itemName}
				</h4>
			</div>
		</div>
	);
}

ItemCard.propTypes = {
	imageUrl: PropTypes.string,
	itemName: PropTypes.string,
	quantity: PropTypes.number,
};

export default ItemCard;
